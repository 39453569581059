import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  query,
  where,
  setDoc,
  doc,
  addDoc,
  getDocs,
  writeBatch,
  deleteDoc,
  collectionGroup,
} from "firebase/firestore/lite";
import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  updateMetadata,
} from "firebase/storage";
import {
  getAuth,
  browserSessionPersistence,
  signInAnonymously,
  signOut,
} from "firebase/auth";
import { getFunctions } from "firebase/functions";
import { getRemoteConfig } from "firebase/remote-config";

export const app = initializeApp({
  apiKey: process.env.REACT_APP_API_KEY,
  appId: process.env.REACT_APP_APP_ID,
  authDomain: `${process.env.REACT_APP_PROJECT_ID}.firebaseapp.com`,
  databaseURL: `https://${process.env.REACT_APP_PROJECT_ID}.firebaseio.com`,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: `${process.env.REACT_APP_PROJECT_ID}.appspot.com`,
});

export const functions = getFunctions(app);
export const storage = getStorage(app);
export const firestore = getFirestore(app);
export const auth = getAuth(app);
export const remoteConfig = getRemoteConfig(app);
export {
  browserSessionPersistence,
  signInAnonymously,
  signOut,
  collection,
  query,
  where,
  setDoc,
  doc,
  addDoc,
  getDocs,
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
  writeBatch,
  deleteDoc,
  updateMetadata,
  collectionGroup,
};
