import React from "react";
import Container from "react-bootstrap/Container";
import styles from "./SempraFooter.module.css";

function SempraFooter() {
  return (
    <footer className={styles.footer}>
      <Container>
        <div>
          © 2020 Sempra Energy. All rights reserved.{"   "}
          <a
            href="https://sempra.com/privacy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy
          </a>
          {" | "}
          <a
            href="https://sempra.com/terms-and-conditions"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms and Conditions
          </a>
          <br />
          <div className={styles.footerCopyright}>
            Sempra North American Infrastructure, Sempra LNG, Sempra Renewables,
            Sempra Mexico, Sempra Texas Utility, Oncor Electric Delivery Company
            LLC (Oncor) and Infraestructura Energética Nova, S.A.B. de C.V.
            (IEnova) are not the same companies as the California utilities, San
            Diego Gas & Electric Company (SDG&E) or Southern California Gas
            Company (SoCalGas), and Sempra North American Infrastructure, Sempra
            LNG, Sempra Renewables, Sempra Mexico, Sempra Texas Utility, Oncor
            and IEnova are not regulated by the California Public Utilities
            Commission.
          </div>
        </div>
      </Container>
    </footer>
  );
}

export default SempraFooter;
