import React from "react";
import EventActionsGrid from "../EventActionsGrid/EventActionsGrid";
import EventContactInformation from "../EventContactInformation/EventContactInformation";
import EventBody from "../EventBody/EventBody";
import { Container } from "react-bootstrap";
import classes from "./Event.module.css";

function Event({ event }) {
  return (
    <React.Fragment>
      <Container>
        <h1 className={classes.heading}>{event.name}</h1>
        <EventBody event={event} />
      </Container>
      <div className={classes.actions}>
        <EventActionsGrid event={event} />
      </div>
      <Container>
        <EventContactInformation contact={event.contact} />
      </Container>
    </React.Fragment>
  );
}

export default Event;
