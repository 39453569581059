import { isAfter, isBefore, isEqual } from "date-fns";
import { timeOf } from "./timeOf";

export function timeRangesIntersect(
  startTimeA,
  endTimeA,
  startTimeB,
  endTimeB
) {
  startTimeA = timeOf(startTimeA);
  endTimeA = timeOf(endTimeA);
  startTimeB = timeOf(startTimeB);
  endTimeB = timeOf(endTimeB);
  return (
    isEqual(startTimeA, startTimeB) ||
    (isAfter(startTimeA, startTimeB) && isBefore(startTimeA, endTimeB)) ||
    isEqual(endTimeA, endTimeB) ||
    (isBefore(endTimeA, endTimeB) && isAfter(endTimeA, startTimeB))
  );
}
