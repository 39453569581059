import React from "react";
import Heading from "../Heading/Heading";
import DownloadLink from "./component/DownloadLink/DownloadLink";
import classes from "./EventResources.module.css";

const EventResources = ({ event }) => {
  const { documents = [] } = event;
  return (
    <React.Fragment>
      <Heading highlighted>
        <h2>Resources</h2>
      </Heading>
      {documents.length === 0 ? (
        <p>There are currently no resources for this event.</p>
      ) : (
        <ul className={classes.list}>
          {documents.map((document, i) => (
            <li key={i}>
              <DownloadLink href={document.url} title={document.name}>
                {document.name}
              </DownloadLink>
            </li>
          ))}
        </ul>
      )}
    </React.Fragment>
  );
};

export default EventResources;
