import React from "react";
import BootstrapFormField from "../../../react-hook-form/BootstrapFormField";
import { Controller } from "react-hook-form";
import { requiredNotBlank } from "../../../form-validation";

function RegistrantFirstName({ control, errors, ...rest }) {
  return (
    <Controller
      name="firstName"
      control={control}
      rules={{
        validate: requiredNotBlank("Required"),
      }}
      render={({ ref, ...props }) => (
        <BootstrapFormField
          error={errors.firstName}
          type="text"
          label="First Name"
          id="firstName"
          {...props}
          {...rest}
        />
      )}
    />
  );
}

export default RegistrantFirstName;
