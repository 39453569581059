import React from "react";
import classes from "./Heading.module.css";

function Heading({
  highlighted = false,
  underlined = false,
  children,
  className,
  ...rest
}) {
  return (
    <div
      className={`${classes.heading} ${
        highlighted ? classes.highlighted : ""
      } ${underlined ? classes.underlined : ""} ${className}`}
      {...rest}
    >
      {children}
    </div>
  );
}

export default Heading;
