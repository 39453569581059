import React from "react";
import BootstrapFormField from "../../../react-hook-form/BootstrapFormField";
import { Controller } from "react-hook-form";
import { requiredNotBlank } from "../../../form-validation";

function RegistrantLastName({ control, errors, ...rest }) {
  return (
    <Controller
      name="lastName"
      control={control}
      rules={{
        validate: requiredNotBlank("Required"),
      }}
      render={({ ref, ...props }) => (
        <BootstrapFormField
          error={errors.lastName}
          type="text"
          label="Last Name"
          id="lastName"
          {...props}
          {...rest}
        />
      )}
    />
  );
}

export default RegistrantLastName;
