import React, { useEffect } from "react";

function EventVirtualMeetingPage({ event }) {
  const { virtualEventLink } = event;
  useEffect(() => {
    //close current window and open meeting link
    window.close();
    window.open(virtualEventLink, "_blank");
  });
  return null;
}

export default EventVirtualMeetingPage;
