import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import {
  Loading,
  Error,
  EventRegistrationThemeProvider,
  EventPage,
  Header,
  Footer,
  RemoteConfigProvider,
  ScrollToTop,
  UserProvider,
} from "@sempra-event-registration/common";
import EventsPage from "./event/page/EventsPage/EventsPage";
import { useAnonymousFirebaseUser } from "./auth/useAnonymousFirebaseUser";

function AppProviders({ children }) {
  return (
    <RemoteConfigProvider>
      <UserProvider>
        <EventRegistrationThemeProvider company="sdge">
          {children}
        </EventRegistrationThemeProvider>
      </UserProvider>
    </RemoteConfigProvider>
  );
}

function AppContent() {
  const { status } = useAnonymousFirebaseUser();
  if (status === "loading" || status === "empty") return <Loading />;
  if (status === "error") return <Error message={"Signing in"} />;

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Header company="sdge" />
      <main>
        <Routes>
          <Route path="/events/:eventId/*" element={<EventPage />}></Route>
          <Route exact path="/events" element={<EventsPage />}></Route>
          <Route
            path="*"
            element={<Navigate to={`/events${window.location.search}`} />}
          />
        </Routes>
      </main>
      <Footer company="sdge" />
    </BrowserRouter>
  );
}

function App() {
  return (
    <AppProviders>
      <AppContent />
    </AppProviders>
  );
}

export default App;
