import React from "react";
import styles from "./EventMealsProvided.module.css";
import { faUtensils } from "@fortawesome/free-solid-svg-icons/faUtensils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function EventMealsProvided({ meals, ...rest }) {
  const formattedMealNames = meals
    .map((meal) => `${meal.name} provided`)
    .join(" | ");

  return (
    <div {...rest}>
      <FontAwesomeIcon icon={faUtensils} />
      <span className={styles.eventMealsProvidedText}>
        {formattedMealNames}
      </span>
    </div>
  );
}

export default EventMealsProvided;
