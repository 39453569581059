import { resourceQuery } from "../../resource";
import { firestore } from "../../firebase";
import {
  doc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore/lite";

function documentToRegistrant(document) {
  const { id, sourceRef, createdAt, updatedAt, ...rest } = document.data();
  return {
    ...rest,
    id: document.id,
    eventId: document.ref.parent.parent.id,
    createdAt: createdAt?.toDate(),
    updatedAt: updatedAt?.toDate(),
  };
}

export const useEventRegistrant = resourceQuery(
  (user, eventId, registrantId) => (onNext, onError) => {
    // If you are an external user who deep linked in with a registration ID use it
    if (registrantId != null) {
      const docRef = doc(
        firestore,
        `readonly_events/${eventId}/readonly_registrations/${registrantId}`,
      );

      return getDoc(docRef).then((snapshot) => {
        if (!snapshot.exists) {
          onError(new Error("Not found"));
        } else {
          onNext(snapshot);
        }
      }, onError);
    }

    // If you are an authenticated internal user use your ID
    if (user.id != null) {
      const collectionRef = collection(
        firestore,
        `readonly_events/${eventId}/readonly_registrations`,
      );
      const q = query(collectionRef, where("internalUserId", "==", user.id));

      return getDocs(q).then((snapshot) => {
        if (snapshot.empty) {
          onError(new Error("Not found"));
        } else {
          onNext(snapshot.docs[0]);
        }
      }, onError);
    }

    // If you are an external user with no registration deep link
    onError(new Error("Not found"));
    return () => {};
  },
)
  .map(documentToRegistrant)
  .mapError((error) => {
    // If a registrant document cannot be found in the collection, that's okay.  No document in the registrants
    // collection means the user is not registered for this event; return an empty object.  Otherwise, return the
    // exception that occurred.  There doesn't seem to be a better way to detect the type of error this is; looking at
    // the message seems to be the best/most reliable approach.
    if (error.message.trim().toLowerCase() === "not found") {
      return;
    }

    console.error("Failed to get registrant", error);

    return error;
  })
  .defaultValue({})
  .build();
