import React from "react";
import SdgeFooter from "./component/SdgeFooter/SdgeFooter";
import SempraFooter from "./component/SempraFooter/SempraFooter";

const footerComponentByCompanyCode = {
  sempra: SempraFooter,
  sdge: SdgeFooter,
};

function Footer({ company = "sempra" }) {
  const Component = footerComponentByCompanyCode[company];
  return <Component />;
}

export default Footer;
