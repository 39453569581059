import React from "react";
import {
  Navigate,
  Route,
  useParams,
  useLocation,
  Routes,
} from "react-router-dom";
import { useLoadedUser } from "../../../auth";
import { useEvent } from "../../hook/useEvent";
import Loading from "../../component/Loading/Loading";
import NotFound from "../../component/NotFound/NotFound";
import EventHead from "../../component/EventHead/EventHead";
import Event from "../../component/Event/Event";
import EventRegistrationPage from "../EventRegistrationPage/EventRegistrationPage";
import EventRegistrationSuccessPage from "../EventRegistrationSuccessPage/EventRegistrationSuccessPage";
import EventJoinWaitlistSuccessPage from "../EventJoinWaitlistSuccessPage/EventJoinWaitlistSuccessPage";
import EventCancelRegistrationPage from "../EventCancelRegistrationPage/EventCancelRegistrationPage";
import EventCancelRegistrationSuccessPage from "../EventCancelRegistrationSuccessPage/EventCancelRegistrationSuccessPage";
import EventDeclineInvitationPage from "../EventDeclineInvitationPage/EventDeclineInvitationPage";
import EventDeclineInvitationSuccessPage from "../EventDeclineInvitationSuccessPage/EventDeclineInvitationSuccessPage";
import EventReleaseWaiverPage from "../EventReleaseWaiverPage/EventReleaseWaiverPage";
import EventCalendarInvitePage from "../EventCalendarInvitePage/EventCalendarInvitePage";
import EventVirtualMeetingPage from "../EventVirtualMeetingPage/EventVirtualMeetingPage";

function EventPage() {
  const { pathname } = useLocation();
  const params = useParams();
  const path = pathname;
  const user = useLoadedUser();

  const { status, value: event, error } = useEvent(user, params.eventId);
  if (status === "empty" || status === "loading") return <Loading />;
  if (status === "error") {
    console.error("Failed to get event", error);
    return <NotFound />;
  }

  return (
    <div>
      <EventHead {...{ event }} />
      <Routes>
        <Route index element={<Event {...{ event }} />} />
        <Route
          path="/register"
          element={<EventRegistrationPage {...{ event }} />}
        />
        <Route
          path="/registered"
          element={
            <EventRegistrationSuccessPage initialRegistration {...{ event }} />
          }
        />

        <Route
          path="/registration-updated"
          element={<EventRegistrationSuccessPage {...{ event }} />}
        />
        <Route
          path="/waiting"
          element={<EventJoinWaitlistSuccessPage {...{ event }} />}
        />
        <Route
          path="/cancel"
          element={<EventCancelRegistrationPage {...{ event }} />}
        />
        <Route
          path="/cancelled"
          element={<EventCancelRegistrationSuccessPage {...{ event }} />}
        />
        <Route
          path="/decline"
          element={<EventDeclineInvitationPage {...{ event }} />}
        />
        <Route
          path="/declined"
          element={<EventDeclineInvitationSuccessPage {...{ event }} />}
        />
        <Route
          path="/release-waiver"
          element={<EventReleaseWaiverPage {...{ event }} />}
        />
        <Route
          path="/calendar-invite"
          element={<EventCalendarInvitePage {...{ event }} />}
        />
        <Route
          path="/virtual-event"
          element={<EventVirtualMeetingPage {...{ event }} />}
        />

        <Route
          path="*"
          element={
            <Navigate to={`/events/${event.id}${window.location.search}`} />
          }
        ></Route>
      </Routes>
    </div>
  );
}

export default EventPage;
