import React from "react";
import { Controller } from "react-hook-form";
import { Form } from "react-bootstrap";
import { companyShortNameByCompanyCode } from "../../../event";

function FlagFirstEvent({ company, control, askIfFirstEvent }) {
  const companyShortName = companyShortNameByCompanyCode[company];
  return (
    <Controller
      name="firstEvent"
      id="firstEvent"
      control={control}
      defaultValue={askIfFirstEvent || false}
      render={({ field: { value, onChange, onBlur }, ...rest }) => (
        <Form.Check
          id="firstEvent"
          type="checkbox"
          label={`This is my first${
            companyShortName ? " " + companyShortName : ""
          } event`}
          checked={value}
          onChange={(e) => {
            onChange(e.target.checked);
          }}
          onBlur={onBlur}
        />
      )}
    />
  );
}

export default FlagFirstEvent;
