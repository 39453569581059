import React from "react";
import SempraButton from "../SempraButton/SempraButton";
import classes from "./EventLocation.module.css";
import Heading from "../Heading/Heading";
import { Editor } from "draft-js";

function addressToGoogleMapUrl(address) {
  const { line1, city, state, zipCode } = address;
  const encodedQuery = encodeURI(`${line1} ${city} ${state} ${zipCode}`);
  return `https://www.google.com/maps/search/?api=1&query=${encodedQuery}`;
}

function EventLocation({ event }) {
  const location = event.location || {};
  const isVirtualEvent = event.isVirtualEvent;

  function onViewMapButtonClick() {
    window.open(addressToGoogleMapUrl(location.address), "_blank");
  }

  const { name, address, additionalDirections } = location;
  return (
    <div>
      <Heading highlighted>
        <h2>Location</h2>
      </Heading>
      {isVirtualEvent ? (
        <div className={classes.block}>
          <strong>Microsoft Teams</strong>
        </div>
      ) : (
        <div>
          <div className={classes.block}>
            <strong>{name}</strong>
          </div>

          {address && (
            <div className={classes.block}>
              <div>{address.line1}</div>
              {address.line2 && <div>{address.line2}</div>}
              <div>
                {address.city}, {address.state} {address.zipCode}
              </div>
            </div>
          )}

          {additionalDirections &&
            additionalDirections.getCurrentContent().hasText() && (
              <Editor editorState={additionalDirections} readOnly={true} />
            )}
          {address && (
            <SempraButton
              text="View Map"
              onClick={onViewMapButtonClick}
              className={classes.block}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default EventLocation;
