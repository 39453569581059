import { useState } from "react";
import {
  emptyResource,
  errorResource,
  loadedResource,
  loadingResource,
} from "../core/resourceFactories";
import { AbstractResourceBuilder } from "./AbstractResourceBuilder";

class ResourceMutationBuilder extends AbstractResourceBuilder {
  constructor(subscribableProvider) {
    super(subscribableProvider);
  }

  build() {
    const { _subscribableProvider, _valueMapper, _errorMapper } = this;
    return () => {
      const [state, setState] = useState(emptyResource());

      function mutate(...args) {
        setState(loadingResource());
        return _subscribableProvider(...args)(
          (value) => {
            try {
              setState(loadedResource(_valueMapper(value)));
            } catch (error) {
              setState(errorResource(_errorMapper(error)));
            }
          },
          (error) => {
            setState(errorResource(_errorMapper(error)));
          }
        );
      }

      return [mutate, state];
    };
  }
}

/**
 * Creates a resource mutation builder
 * @param subscribableProvider Function providing a subscribable function that performs mutation logic
 * @return {ResourceMutationBuilder}
 */
export function resourceMutation(subscribableProvider) {
  return new ResourceMutationBuilder(subscribableProvider);
}
