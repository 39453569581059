import { EditorState, ContentState } from "draft-js";

const GLOBAL_START_DATE = new Date("January 1, 2021 09:00:00");
const DEFAULT_EVENT = {
  name: "test event",
  id: "2cgD5Cu0YEPEwP5248Hm",
  type: "internal",
  createdBy: "testuser@semprautilities.com",
  description: "",
  location: {},
  contact: {},
  meals: [],
  documents: [],
  groups: [],
  workshops: [],
  status: "draft",
  startsAt: GLOBAL_START_DATE,
  endsAt: new Date("Jan 1 2021 14:00:00"),
};

const DEFAULT_GROUP = [
  {
    name: "group 1",
    id: "niusdfyb4yu84f",
    subgroups: [
      { name: "subgroup1", capacity: 25 },
      { name: "subgroup2", capacity: 15 },
    ],
  },
  {
    name: "group 2",
    id: "b4yu84fniusdfy",
    subgroups: [
      { name: "subgroup3", capacity: 4 },
      { name: "subgroup4", capacity: 6 },
    ],
  },
];

const DEFAULT_LOCATION = {
  name: "Test Location",
  additionalDirections: "",
  address: { line1: "123 main st", line2: "san diego, ca" },
  rooms: [],
  useSubgroupCapacities: false,
};

const DEFAULT_DOCUMENT = {
  name: "test document",
  fileName: "test.jpg",
};

const DEFAULT_MEAL = {
  startTime: new Date(),
  endTime: new Date(),
  startsAt: new Date(),
  endsAt: new Date(),
  options: [],
};

const DEFAULT_WORKSHOP = {
  name: "test workshop",
  id: "CnZmp2rMOVWi8RZ4X9d9",
  description: "",
  facilitator: "",
  startTime: GLOBAL_START_DATE,
  endTime: new Date("January 1, 2021 10:00:00"),
  room: {
    name: "test room",
    capacity: 15,
    id: "aT275tEcqiFuysKudcye",
  },
};

const DEFAULT_REGISTRATION = {
  companyName: "SDG&E",
  createdAt: GLOBAL_START_DATE,
  email: "test@sdge.com",
  eventId: "6xNvM3olPqwzz5mbTFmB",
  firstName: "Test",
  group: {},
  id: "HB2gsoga9k1lSthT5smp",
  internalResource: {},
  internalUserId: "testuser@semprautilities.com",
  lastName: "User",
  meals: [],
  phone: "(123) 123-1234",
  status: "registered",
  subgroup: {},
  title: "",
  updatedAt: GLOBAL_START_DATE,
  workshops: [],
};

const DEFAULT_MESSAGES = {
  message1: {
    id: "message1",
    label: "A",
    message: EditorState.createWithContent(ContentState.createFromText("A")),
    messageHtml: "<p>A</p>",
    messageText: "A",
  },
  message2: {
    id: "message2",
    label: "B",
    message: EditorState.createWithContent(ContentState.createFromText("B")),
    messageHtml: "<p>B</p>",
    messageText: "B",
  },
  message3: {
    id: "message3",
    label: "C",
    message: EditorState.createWithContent(ContentState.createFromText("C")),
    messageHtml: "<p>C</p>",
    messageText: "C",
  },
};

export const buildEvent = (overrides = {}) => {
  return { ...DEFAULT_EVENT, ...overrides };
};

export const buildGroup = (overrides = {}) => {
  return [...DEFAULT_GROUP, ...overrides];
};

export const buildLocation = (overrides = {}) => {
  return { ...DEFAULT_LOCATION, ...overrides };
};

export const buildDocument = (overrides = {}) => {
  return { ...DEFAULT_DOCUMENT, ...overrides };
};

export const buildMeal = (overrides = {}) => {
  return { ...DEFAULT_MEAL, ...overrides };
};

export const buildWorkshop = (overrides = {}) => {
  return { ...DEFAULT_WORKSHOP, ...overrides };
};

export const buildRegistration = (overrides = {}) => {
  return { ...DEFAULT_REGISTRATION, ...overrides };
};

export const minuteInMilliseconds = 60000;

export const buildMessages = (overrides = {}) => {
  return { ...DEFAULT_MESSAGES, ...overrides };
};
