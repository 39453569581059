import React from "react";
import BootstrapTable from "react-bootstrap/Table";
import classes from "./Table.module.css";

function TableHead({ className, children, ...rest }) {
  return (
    <thead className={`${classes.thead} ${className}`} {...rest}>
      {children}
    </thead>
  );
}

function TableBody({ children, ...rest }) {
  return <tbody {...rest}>{children}</tbody>;
}

function Table({ className, children, ...rest }) {
  return (
    <BootstrapTable
      borderless
      striped
      responsive
      className={`${classes.table} ${className || ""}`}
      {...rest}
    >
      {children}
    </BootstrapTable>
  );
}

Table.Head = TableHead;
Table.Body = TableBody;

export default Table;
