import { set } from "date-fns";

/**
 * Normalizes the provided date by setting it's year, month, date, seconds and milliseconds to a fixed value so
 * the time (hours and minutes) can be compared correctly with other times.
 *
 * @param date The date to get the time of
 * @return {Date} The normalized date
 */
export function timeOf(date) {
  return set(date, {
    year: 2020,
    month: 0,
    date: 1,
    seconds: 0,
    milliseconds: 0,
  });
}
