import { isBefore } from "date-fns";

export const eventTimeStatuses = ["draft", "open", "cancelled", "complete"];

export const eventStatusNameByValue = {
  draft: "Draft",
  published: "Published",
  cancelled: "Cancelled",
};

export const eventTimeStatusNameByValue = {
  draft: "Draft",
  open: "Open",
  complete: "Complete",
  cancelled: "Cancelled",
};

export function eventToEventTimeStatus({ status, endsAt }) {
  if (status === "published") {
    return isBefore(new Date(), endsAt) ? "open" : "complete";
  }
  return status;
}
