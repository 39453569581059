import { functions } from "../../firebase";
import { promiseMutation } from "../../resource";
import { httpsCallable } from "firebase/functions";

export const useEventRegistrantSaver = promiseMutation(
  (event, registration) => {
    const updateEventRegistration = httpsCallable(
      functions,
      "updateEventRegistration",
    );

    return updateEventRegistration({
      eventId: event.id,
      registration,
    })
      .then((response) => response.data)
      .catch((error) => {
        console.error("Failed to save registration", error);
        throw error;
      });
  },
).build();
