import React from "react";
import { Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import { logoImagesByCompanyCode } from "./logoImagesByCompanyCode";
import classes from "./Header.module.css";

function Header({ children, company = "sempra" }) {
  const imageProps = logoImagesByCompanyCode[company];
  return (
    <header className={classes.header}>
      <Navbar className="justify-content-between">
        <Navbar.Brand>
          <Link to={`/${window.location.search}`}>
            <img {...imageProps} />
          </Link>
        </Navbar.Brand>
        {children}
      </Navbar>
    </header>
  );
}

export default Header;
