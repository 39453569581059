import React from "react";
import classes from "./EventStatusBanner.module.css";

const bannerConfig = {
  registered: {
    className: classes.eventStatusBannerRegistered,
    message: "You're Registered",
  },
  cancelled: {
    className: classes.eventStatusBannerCancelled,
    message: "CANCELLED",
  },
  full: {
    className: classes.eventStatusBannerFull,
    message: "Full - Please Join Waitlist",
  },
  waitlist: {
    className: classes.eventStatusBannerWaitlist,
    message: "You're On The Waitlist",
  },
};

function eventToBanner(event) {
  if (event.status === "cancelled") return bannerConfig.cancelled;

  if (event.registrant && event.registrant.status === "waiting")
    return bannerConfig.waitlist;

  if (event.isRegistered) return bannerConfig.registered;

  if (event.full) return bannerConfig.full;

  return null;
}

function EventStatusBanner({ event }) {
  // TODO status banner not getting refreshed.
  const banner = eventToBanner(event);
  if (banner === null) return null;

  return (
    <div className={`${classes.eventStatusBanner} ${banner.className}`}>
      {banner.message}
    </div>
  );
}

export default EventStatusBanner;
