import React from "react";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classes from "./DownloadLink.module.css";

function DownloadLink({ href, title, children }) {
  return (
    <a
      tabIndex="0"
      className={classes.link}
      target="_blank"
      {...{ href, title }}
    >
      <FontAwesomeIcon icon={faFile} />
      <span className={classes.label}>{children}</span>
    </a>
  );
}

export default DownloadLink;
