import React from "react";
import { CroppedImage } from "../../../components";
import EventDateAndTime from "../EventDateAndTime/EventDateAndTime";
import EventStatusBanner from "../EventStatusBanner/EventStatusBanner";
import { Container } from "react-bootstrap";

import classes from "./EventHead.module.css";

function EventHead({ event }) {
  const { imageUrl } = event;

  return (
    <Container>
      <EventStatusBanner {...{ event }} />
      {imageUrl && (
        <div className={classes.imageContainer}>
          <CroppedImage url={imageUrl} aspectRatio={3} />
        </div>
      )}
      <EventDateAndTime {...{ event }} />
    </Container>
  );
}

export default EventHead;
