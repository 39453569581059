import React from "react";
import classes from "./CroppedImage.module.css";

/**
 * Renders a cropped image
 * @param url The image path
 * @param aspectRatio The width / height (3:1 would be 3)
 * @param children The component children to display within the scalable container
 */
function CroppedImage({ url, aspectRatio = 1, children }) {
  return (
    <div className={classes.container}>
      <div
        className={classes.image}
        style={{
          backgroundImage: `url(${url})`,
          paddingTop: `${(1 / aspectRatio) * 100}%`,
          backgroundColor: "#4a4a4a",
        }}
      />
      {children}
    </div>
  );
}

export default CroppedImage;
