export function copyToClipboard(value) {
  const tempElement = document.createElement("input");
  try {
    document.body.appendChild(tempElement);
    tempElement.setAttribute("id", "copyToClipboardContent");
    tempElement.setAttribute("value", value);
    tempElement.select();
    document.execCommand("copy");
  } catch (error) {
    throw error;
  } finally {
    tempElement.remove();
  }
}
