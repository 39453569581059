import { useEffect, useState } from "react";
import { useUser } from "./UserContext";
import { auth, firestore } from "../firebase";
import { getRedirectResult, onAuthStateChanged } from "firebase/auth";
import { getDoc, doc } from "firebase/firestore/lite";
import { errorResource, loadedResource, loadingResource } from "../resource";

function createUser(firebaseAuthUser, claims, userDocumentData) {
  const { email: id } = firebaseAuthUser;
  const { permissions = [] } = claims;
  return {
    ...userDocumentData,
    type: "internal",
    id,
    permissions,
  };
}

export function userHook({ onSignedOut, onRedirectResult }) {
  return () => {
    const [user, setUser] = useUser();
    const [firebaseUser, setFirebaseUser] = useState();
    const [lastSync, setLastSync] = useState({});
    const onError = (error) => setUser(errorResource(error));

    // useEffect(() => {
    //   if (onRedirectResult != null) {
    //     getRedirectResult(auth)
    //       .then((result) => {
    //         console.log("getRedirectResult 2", result);
    //         setRedirectResult(result);
    //         onRedirectResult(result);
    //       })
    //       .catch(onError);
    //   }
    // }, []);

    useEffect(() => {
      setUser(loadingResource());
      const unsubscribe = onAuthStateChanged(
        auth,
        async (firebaseUser) => {
          console.log("firebaseUser", firebaseUser);
          if (firebaseUser == null) {
            const result = await onSignedOut().catch((e) => {
              console.log("error", e);
              if (e?.code === "auth/popup-blocked") {
                alert(
                  "It looks like popups are blocked in your browser. Please enable popups for this site to proceed with authentication.",
                );
              }
            });
            console.log("onAuthStateChanged result", result);
          } else {
            setFirebaseUser(firebaseUser);
          }
        },
        (error) => {
          onError(error);
        },
      );

      return () => {
        unsubscribe();
      };
    }, []);

    useEffect(() => {
      if (firebaseUser == null) {
        return;
      }
      const docRef = doc(firestore, "internal_users", firebaseUser.email);
      getDoc(docRef).then(async (snapshot) => {
        const { _lastSync: serverLastSync, ...userDocumentData } =
          snapshot.exists ? snapshot.data() : {};

        // The first login will take a minute
        // because the cloud function is responsible for setting this field
        if (serverLastSync != null) {
          // If we detect the client is out of sync with the server we need to wait until we get the
          // latest custom claims before we create our user
          const refreshToken = !serverLastSync.isEqual(lastSync);
          const tokenResult = await firebaseUser.getIdTokenResult(refreshToken);
          const user = createUser(
            firebaseUser,
            tokenResult.claims,
            userDocumentData,
          );

          setLastSync(serverLastSync);
          setUser(loadedResource(user));
        }
      }, onError);
    }, [firebaseUser]);

    return user;
  };
}
