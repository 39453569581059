import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import classes from "./RegistrationSuccessActionsGrid.module.css";
import EventAddToCalendar from "../EventAddToCalendar/EventAddToCalendar";
import EventResources from "../EventResources/EventResources";
import EventLocation from "../EventLocation/EventLocation";

function RegistrationSuccessActionsGrid({
  event,
  registration,
  className,
  ...rest
}) {
  return (
    <Container
      className={`${classes.parentContainer} ${className || ""}`}
      fluid
      {...rest}
    >
      <Container>
        <Row className={classes.informationRowLayout}>
          <Col md={12} lg={4} className={classes.informationColLayout}>
            <EventAddToCalendar event={event} registration={registration} />
          </Col>
          <Col md={12} lg={4} className={classes.informationColLayout}>
            <EventLocation event={event} />
          </Col>
          <Col md={12} lg={4} className={classes.informationColLayout}>
            <EventResources event={event} />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default RegistrationSuccessActionsGrid;
