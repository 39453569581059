import { functions, auth } from "../firebase";
import { userHook } from "./userHooks";
import {
  signInWithPopup as signInWithPopupAuth,
  OAuthProvider,
} from "firebase/auth";
import { httpsCallable } from "firebase/functions";

function authProvider() {
  const provider = new OAuthProvider("microsoft.com");
  provider.setCustomParameters({
    tenant: "a2e7980c-11ea-4838-8f1a-2f497d8c4072",
  });
  provider.addScope("User.Read");
  return provider;
}

async function signInWithRedirect() {
  const provider = authProvider();
  console.log("provider", provider);
  return await signInWithPopupAuth(auth, provider);
}

async function updateUserDocument() {
  const updateUser = httpsCallable(functions, "updateUser");

  return updateUser({}).catch((error) => {
    // Log and continue
    console.error(error);
  });
}

export const useInternalUser = userHook({
  onSignedOut: signInWithRedirect,
  onRedirectResult: () => {
    return updateUserDocument();
  },
});
