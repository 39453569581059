import { isBlank } from "./string";

export const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;

export function isEmail(value) {
  return emailPattern.test(value);
}

export function email(message = "Please enter a valid email address") {
  return (value) => {
    if (!isBlank(value) && !isEmail(value)) {
      return message;
    }
  };
}
