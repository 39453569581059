import React from "react";
import styles from "./SdgeFooter.module.css";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons/faExternalLinkAlt";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function SdgeFooter() {
  return (
    <footer className={styles.footer}>
      <div className="container">
        <div className={styles.linksFormat}>
          <a
            href="https://www.sdge.com/privacy"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.link}
          >
            Privacy <FontAwesomeIcon icon={faExternalLinkAlt} />
          </a>
        </div>

        <div className={styles.copyright}>
          San Diego Gas & Electric® Company is a subsidiary of Sempra Energy®.
          SDG&E® and San Diego Gas & Electric are registered trademarks of San
          Diego Gas & Electric Company.
          <br />
          <div className={styles.footerCopyright}>
            © 1998-2020 San Diego Gas & Electric Company. The trademarks used
            herein are the property of their respective owners. All rights
            reserved.
          </div>
          <img
            className={styles.footerImage}
            src="/sdge-footer-logo.png"
            alt="SDG&E"
          />
        </div>
      </div>
    </footer>
  );
}

export default SdgeFooter;
