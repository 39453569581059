import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import Loading from "../../component/Loading/Loading";
import { createICS } from "../../../event";

function EventCalendarInvitePage({ event }) {
  const [downloaded, setDownloaded] = useState(false);
  const { id, registration } = event;

  useEffect(() => {
    if (downloaded) return;
    createICS(event, registration);
    setDownloaded(true);
  }, [downloaded, event, registration]);

  return !downloaded ? (
    <Loading />
  ) : (
    <Navigate to={`/events/${id}${window.location.search}`} />
  );
}

export default EventCalendarInvitePage;
