import React, { Fragment } from "react";
import classes from "./EventRegister.module.css";
import differenceInDays from "date-fns/differenceInCalendarDays";
import { Link, useNavigate } from "react-router-dom";
import SempraButton from "../SempraButton/SempraButton";
import Heading from "../Heading/Heading";
import { eventToEventTimeStatus } from "../../../event";
import { formatPhoneNumber } from "../../shared/formatting";
import ShareEventButton from "../ShareEventButton/ShareEventButton";

const registrationDaysRemainingThreshold = 7;

function EventRegister({ event }) {
  const navigate = useNavigate();
  const search = window.location.search;
  const { full, id, registration, registrationDeadline, contact = {} } = event;

  function onRegisterNowButtonClick() {
    navigate(`/events/${id}/register${search}`);
  }

  function onCancelRegistrationButtonClick() {
    navigate(`/events/${id}/cancel${search}`);
  }

  const messageByTimeStatus = {
    open: () => {
      const registrationDaysRemaining = differenceInDays(
        new Date(registrationDeadline),
        new Date()
      );

      if (registrationDaysRemaining <= 0) {
        return (
          <Fragment>
            <p>
              We're sorry, the registration deadline has passed for this event.
            </p>
            <p>For post-deadline registration, please contact:</p>
            <div className={classes.block}>
              <div>
                <strong>{contact.name}</strong>
              </div>
              {contact.email && (
                <div>
                  <a href={`mailto:${contact.email}`}>{contact.email}</a>
                </div>
              )}
              {contact.phone && <div>{formatPhoneNumber(contact.phone)}</div>}
            </div>
          </Fragment>
        );
      }
      return (
        <Fragment>
          <p>
            {"Registration closes in "}
            <strong
              className={
                registrationDaysRemaining < registrationDaysRemainingThreshold
                  ? classes.warning
                  : ""
              }
            >
              {registrationDaysRemaining}
              {registrationDaysRemaining === 1 ? " day" : " days"}
            </strong>
          </p>
          {full && (
            <Fragment>
              <p>At this time event registration is full.</p>
              <p>
                Please join our waitlist and we will contact you if a space
                opens up.
              </p>
            </Fragment>
          )}
          <div className={classes.verticalControls}>
            {["registered", "waiting"].includes(registration.status) ? (
              <Fragment>
                <SempraButton
                  text="Change Registration"
                  onClick={onRegisterNowButtonClick}
                />
                <SempraButton
                  text="Cancel Registration"
                  onClick={onCancelRegistrationButtonClick}
                  outlined
                />
              </Fragment>
            ) : (
              <SempraButton onClick={onRegisterNowButtonClick}>
                {full && registration.status !== "invited-from-waitlist"
                  ? "Join Waitlist"
                  : "Register"}
              </SempraButton>
            )}
            <ShareEventButton eventId={id} />
          </div>
        </Fragment>
      );
    },
    complete: () => {
      return (
        <Fragment>
          <p>This event has already come and gone.</p>
          <p>
            To keep up to date with the latest events, please visit{" "}
            <Link to={`/${search}`}>upcoming events</Link>.
          </p>
        </Fragment>
      );
    },
    cancelled: () => {
      return (
        <Fragment>
          <p>
            We're sorry, this event has been{" "}
            <strong className={classes.warning}>cancelled</strong>.
          </p>
          <p>
            To keep up to date with the latest events, please visit{" "}
            <Link to={`/${search}`}>upcoming events</Link>.
          </p>
        </Fragment>
      );
    },
  };

  const timeStatus = eventToEventTimeStatus(event);
  const message = messageByTimeStatus[timeStatus];

  return (
    <div>
      <Heading highlighted>
        <h2>Register</h2>
      </Heading>
      {message && message()}
    </div>
  );
}

export default EventRegister;
