import { remoteConfigDefaults } from "./remoteConfigDefaults";
import { remoteConfigSettings } from "./remoteConfigSettings";
import { remoteConfig } from "../firebase";

/**
 * firebase.remoteConfig() with common settings
 */
export const remoteConfigReference = ((config) => {
  config.defaultConfig = remoteConfigDefaults;
  config.settings = remoteConfigSettings;
  return config;
})(remoteConfig || {});
