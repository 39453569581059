/**
 * Enumerate the types of registrations that are available for users.
 *
 * @key The name of the registration
 * @value The value that should be stored in the database when the registration type is chosen
 */
export const registrationStatus = {
  invited: "invited",
  registered: "registered",
  waiting: "waiting",
  invitedFromWaitlist: "invited-from-waitlist",
  declined: "declined",
  cancelled: "cancelled",
  removed: "removed",
};

export const registrationStatuses = [
  "invited",
  "registered",
  "waiting",
  "invited-from-waitlist",
  "declined",
  "cancelled",
  "removed",
];

export const registrationStatusNameById = {
  invited: "Invited",
  registered: "Registered",
  waiting: "Waiting",
  "invited-from-waitlist": "Invited from Waitlist",
  declined: "Declined",
  cancelled: "Cancelled",
  removed: "Removed",
};

export const declinationStatuses = ["declined", "cancelled"];

export const registeredOrWaiting = ["registered", "waiting"];

export const reservationStatuses = [
  "registered",
  "waiting",
  "invited-from-waitlist",
];

export const absenceStatuses = [...declinationStatuses, "removed"];
