import React from "react";
import { Spinner } from "react-bootstrap";
import classes from "./Loading.module.css";

function Loading({ message = "Loading, Please Wait..." }) {
  return (
    <div className={classes.container}>
      <Spinner animation="grow" />
    </div>
  );
}

export default Loading;
