import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styles from "./EventList.module.css";
import EventCard from "../EventCard/EventCard";
import ViewMoreButton from "../ViewMoreButton/ViewMoreButton";
import { useNavigate } from "react-router-dom";

const defaultEventCount = 6;
const viewMoreEventCount = 6;

function EventList({ events: [featuredEvent, ...remainingEvents] }) {
  const [showCount, setShowCount] = useState(defaultEventCount);
  const navigate = useNavigate();

  const onEventClick = (event) => {
    navigate(`/events/${event.id}${window.location.search}`);
  };

  if (!featuredEvent) {
    return (
      <Container>
        <p>There are no upcoming events. Please check back soon for updates.</p>
      </Container>
    );
  }

  const otherUpcomingEvents = remainingEvents
    .slice(0, showCount)
    .map((event) => {
      return (
        <Col
          key={event.id}
          sm={12}
          md={6}
          lg={4}
          className={styles.eventListColumn}
        >
          <EventCard event={event} onEventClick={() => onEventClick(event)} />
        </Col>
      );
    });

  return (
    <Container>
      {featuredEvent && (
        <Row>
          <Col key={featuredEvent.id} className={styles.featuredEventColumn}>
            <EventCard
              isFeatured
              event={featuredEvent}
              onEventClick={() => onEventClick(featuredEvent)}
            />
          </Col>
        </Row>
      )}
      {otherUpcomingEvents.length > 0 && <Row>{otherUpcomingEvents}</Row>}
      {remainingEvents.length > showCount && (
        <div className={styles.viewMoreButtonContainer}>
          <ViewMoreButton
            onClick={() => {
              // Get scroll position before adding elements to preserve it
              const scrollX = window.scrollX;
              const scrollY = window.scrollY;

              setShowCount(showCount + viewMoreEventCount);

              // Preserve scroll position after adding elements
              setTimeout(() => {
                window.scrollTo(scrollX, scrollY);
              });
            }}
          >
            View More Events
          </ViewMoreButton>
        </div>
      )}
    </Container>
  );
}

export default EventList;
