import React from "react";
import EventDescription from "../EventDescription/EventDescription";
import EventMealsProvided from "../EventMealsProvided/EventMealsProvided";
import EventWorkshops from "../EventWorkshops/EventWorkshops";
import classes from "./EventBody.module.css";

function EventBody({ event, ...rest }) {
  const { description, meals = [], workshops = [] } = event;
  return (
    <div {...rest}>
      {description && description.getCurrentContent().hasText() && (
        <EventDescription value={description} />
      )}
      {meals?.length > 0 && (
        <EventMealsProvided className={classes.section} meals={meals} />
      )}
      {workshops?.length > 0 && (
        <EventWorkshops className={classes.section} workshops={workshops} />
      )}
    </div>
  );
}

export default EventBody;
