import React from "react";
import Button from "react-bootstrap/Button";
import classes from "./SempraButton.module.css";

function SempraButton({
  type,
  text,
  children,
  outlined = false,
  secondary = false,
  loading,
  disabled,
  className,
  ...rest
}) {
  return (
    <Button
      bsPrefix={classes.sempraButton}
      type={type || "button"}
      className={`${outlined ? classes.outlined : ""} ${
        secondary ? classes.secondary : ""
      } ${loading ? classes.loading : ""} ${className}`}
      disabled={disabled || loading}
      {...rest}
    >
      {text != null ? text : children}
    </Button>
  );
}

export default SempraButton;
