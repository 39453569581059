import { firebaseReference, firestore } from "../../firebase";
import { collection, query, orderBy, where } from "firebase/firestore/lite";
import { firestoreCollectionQuery, map, compose } from "../../resource";
import { startOfToday } from "date-fns";
import { registrationStatus } from "../../event/registrationStatus";
import { useEventRegistrants } from "./useEventRegistrants";

function documentToReadonlyEvent(document) {
  const data = document.data();
  return {
    full: data.full,
    id: document.id,
    imageUrl: data.image?.url,
    name: data.name,
    status: data.status,
    startsAt: data.startsAt?.toDate(),
    summary: data.summary,
    type: data.type,
  };
}

function applyQuery(collectionReference, additionalQuery) {
  let startDate = startOfToday();

  return query(
    collectionReference,
    where("startsAt", ">", startDate),
    where("status", "in", ["published", "cancelled"]),
    where("invitationOnly", "==", false),
    additionalQuery,
    orderBy("startsAt", "asc"),
  );
}

export const useExternalReadonlyEvents = firestoreCollectionQuery((user) =>
  applyQuery(
    collection(firestore, "readonly_events"),
    where("audience.userType.external", "==", true),
  ),
)
  .map((snapshot) => snapshot.docs.map(documentToReadonlyEvent))
  .mapError((error) => {
    console.error("Failed to get events", error);
    return error;
  })
  .build();

const useInternalReadonlyEventsInternal = firestoreCollectionQuery(
  (user, q) => {
    console.log("q !!!!!!!!!", q);
    return query(
      collection(firestore, "readonly_events"),
      where("startsAt", ">", startOfToday()),
      where("status", "in", ["published", "cancelled"]),
      where("invitationOnly", "==", false),
      where(`audience.userType.${user.type}`, "==", true),
      where(`audience.company.${user.company}`, "==", true),
      where(`audience.resourceType.${user.resourceType}`, "==", true),
      orderBy("startsAt", "asc"),
    );
  },
  // applyQuery(
  //   query(
  //     collection(firestore, "readonly_events"),
  //     where(`audience.userType.${user.type}`, "==", true),
  //     where(`audience.company.${user.company}`, "==", true),
  //     where(`audience.resourceType.${user.resourceType}`, "==", true)
  //   ),
  //   q,
  // ),
)
  .map((snapshot) => snapshot.docs.map(documentToReadonlyEvent))
  .mapError((error) => {
    console.error("Failed to get events", error);
    return error;
  })
  .build();

export function useInternalReadonlyEvents(user, query) {
  return map(
    compose(
      useInternalReadonlyEventsInternal(user, query),
      useEventRegistrants(user),
    ),
    ([events, registrations]) =>
      events.map((event) => ({
        ...event,
        isRegistered: registrations.some(
          (registration) =>
            registration.eventId === event.id &&
            registration.status === registrationStatus.registered,
        ),
      })),
  );
}
